
import gym from "../assets/projects/gym.svg";
import expensetracker from "../assets/projects/expensetracker.svg";
import notify from "../assets/projects/notify.svg";
export const data = [
  {
    id: 1,
    name: "Gymnist",
    image:gym,
    github: "https://github.com/Ayesha123413/Gymnist.git",
    live: "https://gymnistbyayesha.pages.dev/",
  
  },
  {
    id: 2,
    name: "Expense Tracker",
    image: expensetracker,
    github: "https://github.com/Ayesha123413/ExpenseTracker.git",
    live: "https://expensetrackerbyayesha.pages.dev/",
 
  },
  {
    id: 4,
    name: "Notify",
    image: notify,
    github: "https://github.com/Ayesha123413/Notify.git",
    live: "https://notifynotesbyayesha.pages.dev/",
 
  },
  // {
  //   id: 5,
  //   name: "Airbnb Clone (Mern)",
  //   image: Airbnb,
  //   github: "https://github.com/hanzla-sib/H.Hotle-Booking",
  //   live: "https://youtu.be/MSRv4ToY9nQ",

  // },

  // {
  //   id: 6,
  //   name: "React-DashBoard",
  //   image: Dashboard,
  //   github: "https://github.com/hanzla-sib/React-Dashboard",
  //   live: "https://youtu.be/aErSW3tExdg",

  // },
  // {
  //   id: 7,
  //   name: "React Background Remover",
  //   image: Remover,
  //   github: "https://github.com/hanzla-sib/React-Background_remover",
  //   live: "https://youtu.be/zng-iWq6Pz4",
  
  // },
  // {
  //   id: 8,
  //   name: "Network Analysis (C++)",
  //   image: Graph,
  //   github: "https://github.com/hanzla-sib/Network-Analysis-C-",
  //   live: "https://youtu.be/SnBa_IvpW74",

  // },
  // {
  //   id: 9,
  //   name: "ATM Machine (OOP)",
  //   image: Atm,
  //   github: "https://github.com/hanzla-sib/ATM-MACHINE-OOP",
  //   live: "",

  // },
  // {
  //   id: 10,
  //   name: "Candy Crush (OOP)",
  //   image: Candy,
  //   github: "https://github.com/hanzla-sib/Candy-Crush-C-",
  //   live: "",

  // },
  // {
  //   id: 11,
  //   name: "Dodge EM (C++)",
  //   image: Dodge,
  //   github: "https://github.com/hanzla-sib/Dodge_em-game",
  //   live: "",
  
  // },
  // {
  //   id: 12,
  //   name: "Restaurant Management (C++ OS)",
  //   image: pipe,
  //   github: "https://github.com/hanzla-sib/restaurant-management-system-c-",
  //   live: "",

  // },
  // {
  //   id: 13,
  //   name: "NGO School DB System",
  //   image: DB,
  //   github: "https://github.com/hanzla-sib/NGO-SCHOOL-DATABASE-SYSTEM",
  //   live: "",
 
  // },

  // {
  //   id: 14,
  //   name: "Veteran Meet (MERN)",
  //   image: veteran,
  //   github: "https://github.com/hanzla-sib/VeteranMeet-Mern-Stack",
  //   live: "",
  
  // },
  // {
  //   id: 15,
  //   name: "Daraz Scrapper (Python and Mysql)",
  //   image: Scrapper,
  //   github: "https://github.com/hanzla-sib/Daraz_Scrapper",
  //   live: "",

  // },
];
